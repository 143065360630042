/**
 * Created by colin on 23/06/2016.
 * The Stock Delivery Form has pagerfanta pagination.  We need to
 * set a form submit every time we change pages, to maintain the state
 * of our form.  We have a separate page parameter in our stockdeliveryform
 * which we use to hold current page.  This code extracts the pagerfanta
 * is trying to target and sets our hidden stockdeliveryform page param
 * before submitting the form.
 */
var $ = require('jquery');

var requestStockDeliveryModule = {

   ready: function () {
        module.exports.start();
    },

    start: function () {

        //console.log('setting click event');
        $('form[name="taskstockdelivery"] .pagination li > a').on("click", function(event) {

            // stop href GET request
            event.preventDefault();
            //console.log("clicked pagination");
            // get our page parameter from the pagerfanta
            // an set it on our hidden field
            var url = ($(this).attr('href'));
            var page = module.exports.getURLParameter(url, 'page');

            // set page before submit
            $("#taskstockdelivery_page").val(page);

            // now submit our form
            //console.log('now submit form');

            // do not add page to action now as we have it in POST params
            //$('#requestStockDeliveryForm').attr('action', this.href);
            $('form[name="taskstockdelivery"]').trigger("submit");
            return false;
        });
    },
    
    getURLParameter: function(url, name) {
        return (RegExp(name + '=' + '(.+?)(&|$)').exec(url)||[,null])[1];
    }
};
module.exports = requestStockDeliveryModule;