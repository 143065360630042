/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
import 'jquery';
import { Collapse } from 'bootstrap';

// main moment library gets pulled in by moment-timezone
import moment from 'moment';
window.moment = moment;

import pikaday from 'pikaday';
window.Pikaday = pikaday;

// require jQuery normally
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

// start the Stimulus application
import './bootstrap';

var loginModule = require('./js/modules/loginModule.js');
var requestStockDeliveryModule = require('./js/modules/requestStockDeliveryModule.js');
var pikadayModule = require('./js/modules/pikadayModule.js');

$(document).ready(function () {
    loginModule.ready();
    requestStockDeliveryModule.ready();
    pikadayModule.ready();
});
