/**
 * The module loaded on the homepage
 */
var $ = require('jquery');

var loginModule = {

   ready: function () {
        module.exports.showContainers();
    },

    showContainers: function () {

        // show login box and hide javascript disabled message
        // if we have javascript enabled
        $('div#loginContainer').css('display','');
        $('div#javascriptDisabled').css('display','none');

        // test if cookies enabled
        if (module.exports.areCookiesEnabled()) {
            // cookies are enabled
        } else {
            // cookies are disabled 
            $('div#loginContainer').css('display','none');
            $('div#cookiesDisabled').css('display','');
        }
    },

    areCookiesEnabled: function () {
        var cookieEnabled = (navigator.cookieEnabled) ? true : false;
 
        if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled)
        {
            document.cookie="testcookie";
            cookieEnabled =
                (document.cookie.indexOf("testcookie") != -1) ? true : false;
        }
        return (cookieEnabled);
    }
};
module.exports = loginModule;
