/**
 * The module for adding datepicker
 */
var $ = require('jquery');

var pikadayModule = {

  ready: function () {
    module.exports.addDatePicker();
  },

  addDatePicker: function () {

    $('.js-datepicker').each(function(index, element) {
      // data-date-format is changed to camel case by JS
      let dateFormat = element.dataset.dateFormat;
     
      // set default date format if not defined
      if (dateFormat === undefined) {
        dateFormat = 'YYYY-MM-DD';
      }

      new Pikaday({
          field: element,
          format: dateFormat,
          toString(date, format) {
            return moment(date).format(dateFormat);
          },
      });
    });
  }
};
module.exports = pikadayModule;